import { icons } from '@portal/components'
import { useQuery } from '@tanstack/react-query'
import { SystemCollections } from 'components/dittoAppConnection'
import { useRPCHook } from 'components/hooks/rpc'
import paths from 'paths'
import { useCallback } from 'react'
import { Link } from 'react-router-dom'

import { useAppManagementContext } from './context'

const { ArrowUpRight } = icons

type Props = {
  appId: string
}

export default function AppForgeLink({ appId }: Props) {
  const { cloudDomainUrl: cloudUrl } = useAppManagementContext()
  const { executeDqlQuery, rpcAccessToken } = useRPCHook({ appId, cloudUrl })

  const getProject = useCallback(async () => {
    const { items } = await executeDqlQuery(
      `SELECT * FROM ${SystemCollections.FORGE_PROJECTS_COLLECTION} LIMIT 1`,
    )
    const projectID = items.at(0)?._id
    return typeof projectID === 'string' ? projectID : undefined
  }, [executeDqlQuery])

  const { data: projectID } = useQuery(['forgeProject', appId], getProject, {
    enabled: !!rpcAccessToken,
  })

  const url = paths.forge({ appId, projectId: projectID })

  return (
    <Link
      to={url}
      className="inline-flex items-center space-x-2 bg-background-surface-hovered shrink-0 rounded-full py-1 pl-1 pr-3"
      data-testid="forgeLink"
    >
      <span className="bg-[#A32100] text-white px-2 py-1 text-sm items-center rounded-full justify-center">
        Alpha
      </span>

      <div className="flex items-center space-x-1">
        <span>App builder</span>
        <ArrowUpRight className="h-4 w-4 text-foreground-subtle" />
      </div>
    </Link>
  )
}
