import graphql__fd95f0b9cc0233b2e1d5 from "./__generated__/OrganizationAppIdResolverQuery.graphql.ts";import graphql from 'babel-plugin-relay/macro'
import paths from 'paths'
import { Suspense } from 'react'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { Navigate, useParams } from 'react-router-dom'

import { OrganizationAppIdResolverQuery } from './__generated__/OrganizationAppIdResolverQuery.graphql'
import AppManagementLoader from './AppManagementLoader'

const query = graphql__fd95f0b9cc0233b2e1d5

type OrganizationAppIdResolverParams = {
  organizationSlug: string
  appSlug: string
}

// Resolves an app ID based on an organization and app slug.
const OrganizationAppIdResolver = () => {
  const params = useParams<OrganizationAppIdResolverParams>()

  const { viewer } = useLazyLoadQuery<OrganizationAppIdResolverQuery>(
    query,
    { organizationSlug: params.organizationSlug!, appSlug: params.appSlug! },
    {
      fetchPolicy: 'network-only',
    },
  )

  const appId = viewer.app?.id
  if (!appId) {
    const redirectUrl = `${paths.userApps({
      userId: viewer.me!.id,
    })}?appNotFound=${params.appSlug}`
    return <Navigate to={redirectUrl} replace />
  }

  return (
    <Suspense fallback={null}>
      <AppManagementLoader appId={appId} />
    </Suspense>
  )
}

export default OrganizationAppIdResolver
