import { useAppContext } from 'context'
import React, { Suspense, useContext } from 'react'
import { Route, Routes } from 'react-router-dom'
import { useFeatureFlags } from 'utils/featureFlags'

import AppManagementRouterRedirect from './AppManagementRouterRedirect'
import AppSettingsContext from './context'
import AppAuthRouter from './tabs/auth/AppAuthRouter'
import AppDevicesRouter from './tabs/devices/AppDevicesRouter'
import { AppSettingsRouter } from './tabs/settings'

const AppCollectionsScene = React.lazy(() => import('./tabs/collections'))
const AppMetricsScene = React.lazy(() => import('./tabs/metrics'))
const AppWebhooksScene = React.lazy(() => import('./tabs/liveQueryWebhooks'))
const AppCDCRouter = React.lazy(() => import('./tabs/cdc'))

type Props = {
  // Current app ID
  appId: string
}

/** Handles the routes used for managing apps. */
export default function AppManagementRouter({ appId }: Props) {
  const { isSuperAdmin } = useAppContext()
  const {
    isDomainReady,
    allowAppWebhooks,
    viewerCanAccessMetrics,
    viewerCanReadAppData,
    viewerCanReadApiKey,
    viewerCanReadLicense,
    allowStreams,
  } = useContext(AppSettingsContext)
  const { deviceDashboard } = useFeatureFlags()

  const atLeastOneAuthSection =
    viewerCanReadLicense || viewerCanReadApiKey || viewerCanReadAppData
  const showAuth = atLeastOneAuthSection || !!isSuperAdmin

  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path="settings/*" element={<AppSettingsRouter />} />
        {(viewerCanReadAppData || isSuperAdmin) && (
          <Route
            path="collections"
            element={<AppCollectionsScene appId={appId} />}
          />
        )}
        {deviceDashboard && viewerCanReadAppData && (
          <Route path="devices/*" element={<AppDevicesRouter />} />
        )}
        {viewerCanAccessMetrics && (
          <Route path="metrics" element={<AppMetricsScene />} />
        )}
        {isDomainReady && allowAppWebhooks && (
          <Route path="webhooks" element={<AppWebhooksScene appId={appId} />} />
        )}
        {allowStreams && <Route path="cdc/*" element={<AppCDCRouter />} />}
        {showAuth && (
          <Route path="connect/*" element={<AppAuthRouter appId={appId} />} />
        )}
        <Route
          path="*"
          element={
            <AppManagementRouterRedirect
              area={atLeastOneAuthSection ? 'connect' : 'settings'}
            />
          }
        />
      </Routes>
    </Suspense>
  )
}
